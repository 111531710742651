import { create } from 'zustand'

import type {
  HubDashLayoutCard,
  HubDashUser,
} from 'src/components/HubDash/lib/types'

interface HubDashStore {
  clearStore: () => void
  layout: number
  setLayout: (layoutId: number) => void
  layoutData: HubDashLayoutCard
  setLayoutData: (layoutData: HubDashLayoutCard) => void
  token: string
  setToken: (token: string) => void
  userList: HubDashUser[]
  setUserList: (userList: HubDashUser[]) => void
  selectedUsers: string[]
  setSelectedUsers: (selectedUsers: string[]) => void
  isUnlocked: boolean
  setIsUnlocked: (isUnlocked: boolean) => void
  cardDrawerOpen: boolean
  setCardDrawerOpen: (cardDrawerOpen: boolean) => void
  baserowUsername: string
  setBaserowUsername: (baserowUsername: string) => void
  baserowPassword: string
  setBaserowPassword: (baserowPassword: string) => void
}

const useHubDashStore = create<HubDashStore>((set) => ({
  clearStore: () =>
    set({ isUnlocked: false, cardDrawerOpen: false, selectedUsers: [] }),
  token: null,
  setToken: (token) => set({ token }),
  layout: null,
  setLayout: (layout) => set({ layout }),
  layoutData: null,
  setLayoutData: (layoutData) => set({ layoutData }),
  userList: [],
  setUserList: (userList) => set({ userList }),
  selectedUsers: [],
  setSelectedUsers: (selectedUsers) => set({ selectedUsers }),
  isUnlocked: false,
  setIsUnlocked: (isUnlocked) => set({ isUnlocked }),
  cardDrawerOpen: false,
  setCardDrawerOpen: (cardDrawerOpen) => set({ cardDrawerOpen }),
  baserowUsername: '',
  setBaserowUsername: (baserowUsername) => set({ baserowUsername }),
  baserowPassword: '',
  setBaserowPassword: (baserowPassword) => set({ baserowPassword }),
}))

export default useHubDashStore
