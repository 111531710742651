import React from 'react'

import {
  styled,
  createTheme,
  Checkbox as MuiCheckbox,
  ThemeProvider,
  CheckboxProps,
} from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    success: {
      main: '#16a34a',
    },
    error: {
      main: '#ef4444',
    },
  },
})

const Checkbox = styled((props: CheckboxProps) => (
  <ThemeProvider theme={theme}>
    <MuiCheckbox {...props} />
  </ThemeProvider>
))(() => ({}))

export default Checkbox
